<template>
	<div class="rentQi">
		<a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="{ span: 4 }" :wrapper-col="{ span: 11 }">
			<a-form-model-item label="优惠名称" prop="Name">
				<a-input v-model="form.Name" allowClear />
			</a-form-model-item>
			<a-form-model-item label="类型" prop="ConcessionType">
				<a-select show-search allowClear placeholder="请选择" v-model="form.ConcessionType" disabled>
					<a-select-option v-for="item in ConcessionTypeList" :key="item.value" :value="item.value">
						{{ item.label }}
					</a-select-option>
				</a-select>
			</a-form-model-item>
			<!--      <a-form-model-item label="课包简介">
        <a-tree-select v-model="form.ClassFeeCategory" :tree-data="treeData2" :replaceFields="{ key: 'Id', value: 'title' }" placeholder="请选择" allowClear> </a-tree-select>
      </a-form-model-item> -->
			<a-form-model-item label="永不过期">
				<a-select allowClear placeholder="请选择" v-model="form.NeverExpire"  @change="NeverExpireChange">
					<a-select-option value="0">否</a-select-option>
					<a-select-option value="1">是</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label="有效期" v-show="isShowEffect" prop="Effect">
				<a-range-picker v-model="form.Effect" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"
					:placeholder="['开始时间', '结束时间']" allowClear @change="dateChange" />
			</a-form-model-item>
			<a-form-model-item label="优惠方案类型" prop="ConcessionIsMain">
				<a-select show-search allowClear placeholder="请选择" v-model="form.ConcessionIsMain">
					<a-select-option v-for="item in ConcessionIsMainList" :key="item.value" :value="item.value">
						{{ item.label }}
					</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label="折扣率(%)">
				<a-input-number v-model="form.AmountDiscount" allowClear style="width:390px;"  />&nbsp;&nbsp;%
			</a-form-model-item>
			<a-form-model-item label="抵减现金">
				<a-input-number v-model="form.AmountCash" allowClear style="width:390px;"  />&nbsp;&nbsp;元
			</a-form-model-item>
			<a-form-model-item label="赠送课次">
				<a-input-number v-model="form.AmountLesson" allowClear style="width:390px;"  />&nbsp;&nbsp;次
			</a-form-model-item>
			<a-form-model-item label="赠送券的类别" prop="CourseVoucherId">
				<a-select show-search allowClear placeholder="请选择" v-model="form.CourseVoucherId">
					<a-select-option v-for="item in CourseVoucherList" :key="item.Id" :value="item.Id">
						{{ item.Name }}
					</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label="赠送时长">
				<a-input-number v-model="form.GiftTime" allowClear style="width:390px;"  />&nbsp;&nbsp;月
			</a-form-model-item>
			<a-form-model-item label="适用范围">
				<a-select allowClear placeholder="请选择" v-model="form.EffectArea" @change="EfectAreaChange">
					<a-select-option :value="1">全体</a-select-option>
					<a-select-option :value="2">指定部门</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label=" " :colon="false" prop="EffectSchoolId" v-show="isShowDepartment">
				<a-tree-select v-model="form.EffectSchoolId" :tree-data="treeData" placeholder="请选择" allowClear
					tree-checkable @change="EffectSchoolChange"> </a-tree-select>
			</a-form-model-item>
			<a-form-model-item label="适用对象">
				<a-select show-search allowClear placeholder="请选择" v-model="form.ApplyToObjects">
					<a-select-option v-for="item in ApplyToObjectsList" :key="item.value" :value="item.value">
						{{ item.label }}
					</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label="收费标准">
				<a-select allowClear placeholder="请选择" v-model="form.EffectClassFeeId" mode="multiple"
					optionFilterProp="label" ref="EffectClassFeeId"
					@change="handleChange('EffectClassFeeId','EffectClassFeeName')">

					<a-select-option v-for="item in ClassType" :key="item.Id" :label="item.Name">
						{{ item.Name }}
					</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label="是否需要审核">

				<a-select allowClear placeholder="请选择" v-model="form.NeedReview">
					<a-select-option value="0">否</a-select-option>
					<a-select-option value="1">是</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label="适用月龄">
				<a-input-number v-model="form.EffectAgeStart" allowClear /> ~
				<a-input-number v-model="form.EffectAgeEnd" allowClear />
			</a-form-model-item>
			<a-form-model-item label="描述">
				<a-textarea v-model="form.Note" :auto-size="{ minRows: 3, maxRows: 5 }" placeholder="描述" />
			</a-form-model-item>

		</a-form-model>
	</div>
</template>

<script>
	export default {
		name: 'rentQi',
		data() {
			return {
				form: {
					Name: '',
					ConcessionType: 1,
					ConcessionIsMain: 0,
					NeverExpire: "0",
					Effect: [],
					AmountDiscount: 0,
					AmountCash: '',
					EffectArea: 1,
					CourseVoucherId: '',
					AmountLesson: '',
					GiftTime: '',
					ApplyToObjects: '',
					EffectSchoolId: undefined,
					EffectClassFeeId: undefined,
					NeedReview: "0",
					CreateUserId: ''
				},
				isShowDepartment: false,
				rules: {
					Name: [{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}],
					ConcessionType: [{
						required: true,
						message: '请选择类型',
						trigger: 'change'
					}],
					CourseVoucherId: [{
						required: true,
						message: '请输入赠送券的类别',
						trigger: 'blur'
					}],
					ConcessionIsMain: [{
						required: true,
						message: '请选择优惠方案类型',
						trigger: 'blur'
					}],
					AmountDiscount: [{
						required: true,
						message: '请输入折扣率',
						trigger: 'blur'
					}],
					AmountCash: [{
						required: true,
						message: '请输入抵减现金',
						trigger: 'blur'
					}],
					Effect :[{
						required: true,
						message: '请选择有效时间',
						trigger: 'change'
					}]
				},
				isShowEffect:true,
				userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
				treeData: [],
				CourseVoucherList: [],
				ConcessionTypeList: [],
				ApplyToObjectsList: [],
				ClassType: [],
				ConcessionIsMainList: []
			}
		},
		props: {
			formData: {
				type: Object,
				default: () => {
					return {}
				}
			}
		},
		created() {

			// 如果是编辑数据，修正表单数据类型
			if (this.formData.Id) {

				for (const key in this.form) {
					if (typeof this.form[key] === 'boolean') {
						this.form[key] = !!this.formData[key]
					} else if (key === 'EffectSchoolId' || key === 'EffectClassFeeId') {
						this.form[key] = this.formData[key] ? this.formData[key].split(',') : []
						if (this.formData['EffectSchoolId'] != "") {
							this.isShowDepartment = true;
						}
					} else if (key === 'Effect') {
						this.form.Effect = [this.formData.EffectFrom, this.formData.EffectTo]
					} else {
						this.form[key] = this.formData[key]
					}
				}
			}
			//课程优惠方案赠送券的类别
			this.$axios.get(80811003, {
				label: 'FeeClass'
			}, res => {
				if (res.data.code == 1) {
					this.CourseVoucherList = res.data.data

				}
			})
			//获取课程类别
			this.$axios.get(80811008, {
				label: 'FeeClass'
			}, res => {
				if (res.data.code == 1) {
					this.ConcessionTypeList = res.data.data

				}
			})
			this.$axios.get(80811009, {
				label: 'FeeClass'
			}, res => {
				if (res.data.code == 1) {
					this.ConcessionIsMainList = res.data.data
				}
			})
			//课程优惠方案适用对象类型
			this.$axios.get(80811010, {
				label: 'FeeClass'
			}, res => {
				if (res.data.code == 1) {
					this.ApplyToObjectsList = res.data.data

				}
			})
			// 获取部门
			this.$axios.get(
				5836120, {
					userId: this.userinfo.uid
				},
				res => {
					if (res.data.code == 1) {
						this.treeData = res.data.data.schoollist
						// this.StatusList = res.data.data.statuslist
					}
				}
			)

			this.ClassTypeList()
		
		},
		methods: {
			EffectSchoolChange(val){
				let department = val.join()
              
				this.ClassTypeList(department)
			},
			NeverExpireChange(val) {
				let rules = {
					...this.rules
				}
				if (val === "1") {
					this.form.Effect = []
					if (rules.Effect) {
						this.$refs.ruleForm.clearValidate('Effect')
					}
					this.isShowEffect=false
					delete rules.NeverExpire
				} else {
					rules.Effect = [{
						required: true,
						message: '请选择有效时间',
						trigger: 'change'
					}]
					this.isShowEffect=true
				}
				this.rules = {
					...rules
				}
			},
			// 适用范围选择事件
			EfectAreaChange(val) {
				let rules = {
					...this.rules
				}
				this.isShowDepartment = val === 2
				if (val !== 2) {
					this.form.EffectSchoolId = ''
					if (rules.EffectSchoolId) {
						this.$refs.ruleForm.clearValidate('EffectSchoolId')
					}

					delete rules.EffectSchoolId
				} else {
					rules.EffectSchoolId = [{
						required: true,
						message: '请选择部门',
						trigger: 'change'
					}]
				}
				this.rules = {
					...rules
				}
			},
			ClassTypeList(val) {
				//获取课程类别
				this.$axios.get(80811004, {
					SchoolId: val
				}, res => {
					if (res.data.code == 1) {
						this.ClassType = [...res.data.data]
					}
				})

			},
			// 日期控件值变化
			dateChange(val) {
				if (val[0]) {
					this.form.EffectFrom = val[0]
					this.form.EffectTo = val[1]
				} else {
					this.form.EffectFrom = ''
					this.form.EffectTo = ''
				}
			},
			handleChange(key, val) {
				if (this.form[key].some(item => item === 'all')) {
					this.form[key] = ['all']
					this.$refs[key].blur()
				}
			},
			onSubmit() {
				return new Promise((resolve, reject) => {
					this.$refs.ruleForm.validate(valid => {
						if (valid) {
							resolve(this.form)
						} else {
							reject(false)
						}
					})
				})
			}
		}
	}
</script>

<style scoped lang="less">
	.rentQi {
		background: #ffffff;
		margin-top: 15px;
		min-height: 800px;
		padding: 15px;
	}
</style>
